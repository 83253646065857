export const signInUserData = [
    {
        id: '21',
        avatar: '/img/avatars/thumb-10.jpg',
        userName: 'Fast Staking',
        email: 'root@fastak.ing',
        authority: ['admin', 'user'],
        password: 'Pool12345$$$',
        accountUserName: 'admin',
    },
]
