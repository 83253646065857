import { THEME_ENUM } from 'constants/theme.constant'

/**
 * Since some configurations need to be match with specific themes,
 * we recommend to use the configuration that generated from demo.
 */

export const themeConfig = {
    "themeColor": "blue",
    "direction": "ltr",
    "mode": "dark",
    "primaryColorLevel": 600,
    "cardBordered": false,
    "panelExpand": false,
    "controlSize": "md",
    "navMode": "dark",
    "layout": {
      "type": "stackedSide",
      "sideNavCollapse": false
    }
  }
